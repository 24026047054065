<template>
	<div class="goals-content" :class="{ 'small-width': small_width }">
		<div v-for="category in categories" class="box" :key="category.id">
			<div class="box-side">
				<ui-icon :name="category.icon" :size="32" />
			</div>
			<div class="box-content">
				<div class="box-title" :class="category.title_color">{{ category.title }}</div>
				<div class="box-goals">
					<ui-text v-if="category.role" :text="category.role" />
					<div v-else><ul><li>None</li></ul></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiText from '@/components/ui/ui-text'

export default {
	name: 'goals-content',
	data() {
		return {
			small_width: false,
			category_templates: [
				{
					id: 1,
					slug: 'what_you_should_do',
					title: "What you should do",
					title_color: 'blue',
					icon: 'menu/menu-profile'
				},
				{
					id: 2,
					slug: 'want',
					title: "what you want to happen in this situation",
					title_color: 'green',
					icon: 'thumb-up'
				},
				{
					id: 3,
					slug: 'personal_goals',
					title: "Your personal goals",
					title_color: 'blue',
					icon: 'menu/menu-goals'
				},
				{
					id: 4,
					slug: 'dont_want',
					title: "What you don't want to happen in this situation",
					title_color: 'red',
					icon: 'thumb-down'
				}
			]
		}
	},
	computed: {
		...mapGetters([ 'logged_user' ]),
		categories() {
			let categories = []
			this.category_templates.forEach((category) => {
				if (this.logged_user.role[category.slug]) {
					categories.push({ ...category, role: this.logged_user.role[category.slug] })
				}
			})
			return categories
		}
	},
	methods: {
		resize() {
			this.small_width = this.$el.clientWidth < 660
		}
	},
	mounted() {
		this.resize()
		window.addEventListener('resize', this.resize)
	},
	components: { UiText }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


.goals-content
	flex flex-start flex-start
	flex-wrap wrap
	&.small-width
		.box
			width 100%
			margin 16px 0
	.box
		width calc(50% - 32px)
		margin 16px
		color var(--color-goals-title)
		.box-side
			float left
			width 40px
			height 100%
			padding 0 8px 0 0
		.box-content
			padding 0 0 0 16px
			margin-left 48px
			border-left 2px solid var(--color-goals-title)
			.box-title
				min-height 32px
				margin 0 0 8px 0
				flex flex-start center 
				text-transform uppercase
				&.green
					color var(--color-goals-positive-text)
				&.red
					color var(--color-goals-negative-text)
			.box-goals
				color var(--color-goals-text)
				ul
					list-style disc
					padding-left 20px
					li
						margin 0 0 16px 0



</style>
