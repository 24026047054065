<template>
	<div class="view view-goals">

		<div class="content">
			<div class="scroll" v-scrollbar>
				<div>
					<goals />
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import Goals from '@/components/game/goals/goals'

export default {
	name: 'view-goals',
	data() {
		return {
			
		}
	},
	components: { Goals }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile, #app.tablet-vertical, #app.tablet-horizontal
	.view-goals .content
		width 100%

.view-goals
	.content
		height 100%
		width calc(100% - 420px)


	
</style>
