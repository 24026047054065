<template>
	<div class="main-title" :class="{ closable }">
		<ui-icon v-if="icon" class="icon" :name="icon" :size="48" />
		<span v-if="text" class="name" v-html="text"></span>
		<ui-link v-if="closable" to="/game" class="close"><ui-icon name="close-blue-dark" :size="16" /></ui-link>
	</div>
</template>

<script>
export default {
	name: 'main-title',
	data() {
		return {
			
		}
	},
	props: {
		icon: { default: '' },
		text: { default: '' },
		closable: { default: true }
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile, #app.tablet-vertical, #app.tablet-horizontal
	.main-title
		margin-top 0
		.close
			top 0
			// width 48px
			// height 48px


.main-title
	display block
	margin 16px 0
	text-transform uppercase
	font-size 2.5rem
	// white-space nowrap
	color var(--color-primary)
	user-select none
	&.closable
		padding-right 32px
	.icon
		float left
		width 48px
		height 48px
		margin 0 8px 0 0
	.name
		flex flex-start center
		height 48px
		line-height 24px
		padding 4px 0 0 0
	.close
		position absolute
		top calc(50% - 20px)
		right 0
		width 40px
		height 40px
		background var(--color-close-button-bg)
		border-radius 50%
		color var(--color-close-button-text)
		cursor pointer
		flex center center
		transition transform 0.1s easeOutQuart
		&:hover
			transform scale(1.05)
		&:active
			transform scale(0.95)



</style>
