<template>
	<div class="goals">
		<main-title text="Goals" icon="menu/menu-goals" />
		<!-- <div class="intro">{{ logged_user.role.goals }}</div> -->
		<ui-text class="intro" :text="logged_user.role.goals" />

		<goals-content />
		
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import GoalsContent from '@/components/game/goals/goals-content'
import MainTitle from '@/components/game/main-title'
import UiText from '@/components/ui/ui-text'

export default {
	name: 'goals',
	computed: {
		...mapGetters([ 'logged_user' ])
	},
	components: { GoalsContent, MainTitle, UiText }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


.goals
	height 100%
	padding 16px 32px
	.intro
		margin 0 0 16px 0
		color var(--color-text)
		// text-transform uppercase



</style>
