import { render, staticRenderFns } from "./main-title.vue?vue&type=template&id=142af988&scoped=true&"
import script from "./main-title.vue?vue&type=script&lang=js&"
export * from "./main-title.vue?vue&type=script&lang=js&"
import style0 from "./main-title.vue?vue&type=style&index=0&id=142af988&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "142af988",
  null
  
)

export default component.exports